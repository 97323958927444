import { defaultEvent, Event } from 'models/Event';

import hosts from '../hosts';
import request from '..';

const offerTypes = {
  ticket: 'tickets',
  guestlist: 'guest-lists',
  bottleservice: 'bottle-services',
  season_pass: 'season-passes',
};

export class EventController {
  static getInfo = async ({ legacyId, id, channel, preview, token, lang }) => {
    let event;

    if (!preview)
      event = await request({
        path: `/v1/events/${legacyId || id}`,
        baseUrl: hosts.EVENTS_SERVICE,
        queryStrings: {
          channel,
          lang,
        },
        headers: { 'Accept-Language': lang },
      });
    else if (token) {
      event = await request({
        path: `/v1/nightgraph/events/${legacyId || id}`,
        baseUrl: hosts.EVENTS_SERVICE,
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': lang,
        },
      });
      event.venue = event.club;
    }

    return event ? new Event(event) : defaultEvent;
  };

  static getAll = async ({ baseParams, optionalParams, lang }) => {
    const events = await request({
      path: `/v1/events`,
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: {
        ...baseParams,
        ...optionalParams,
      },
      headers: { 'Accept-Language': lang },
    });

    return events.map(event => new Event(event));
  };

  static getLineup = ({ id, preview, token, lang }) => {
    if (preview && !token) return [];
    if (preview) {
      return request({
        path: `/v1/nightgraph/events/${id}/line-up`,
        baseUrl: hosts.EVENTS_SERVICE,
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': lang,
        },
      });
    }
    return request({
      path: `/v1/events/${id}/line-up`,
      baseUrl: hosts.EVENTS_SERVICE,
      headers: { 'Accept-Language': lang },
    });
  };

  static getOffers = ({ id, channel, isPreview = false, token = null, lang, ...params }) => {
    if (!id) return null;
    if (!isPreview) {
      return request({
        path: `/events/${id}/offers`,
        baseUrl: hosts.OFFER_SERVICE,
        queryStrings: {
          channel,
          lang,
          ...params,
        },
        headers: { 'Accept-Language': lang },
      });
    }
    return request({
      path: `/nightgraph/events/${id}/admissions`,
      baseUrl: hosts.OFFER_SERVICE,
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': lang,
      },
    });
  };

  static getOfferById = ({ id, channel, eventId, admissionType, lang }) => {
    const offerType = offerTypes[admissionType];
    const path =
      offerType === 'season-passes'
        ? `/events/${eventId}/season-passes/${id}`
        : `/${offerType}/${id}`;
    return request({
      path,
      baseUrl: hosts.OFFER_SERVICE,
      queryStrings: {
        channel,
        lang,
      },
      headers: { 'Accept-Language': lang },
    });
  };

  static getOffersByAreaId({ floorplanId, areaId, lang }) {
    return request({
      path: `/floorplans/${floorplanId}/areas/${areaId}/offers`,
      baseUrl: hosts.OFFER_SERVICE,
      headers: { 'Accept-Language': lang },
    });
  }

  static getFloorplan = ({ id, lang }) => {
    return request({
      path: `/floorplans/${id}`,
      baseUrl: hosts.OFFER_SERVICE,
      headers: { 'Accept-Language': lang },
    });
  };

  static getTables = ({ id, lang }) => {
    return request({
      path: `/v1/floorplans/${id}/tables`,
      baseUrl: hosts.TABLE_MANAGEMENT,
      headers: { 'Accept-Language': lang },
    });
  };

  static registerChannelVisit = ({ id, channel, lang }) => {
    if (!id) return null;
    return request({
      path: `/v1/events/${id}/visit`,
      method: 'POST',
      baseUrl: hosts.EVENTS_SERVICE,
      queryStrings: {
        channel,
      },
      headers: { 'Accept-Language': lang },
    });
  };

  static holdTicketTable = ({ tableId, lang }) =>
    request({
      method: 'POST',
      path: `/v1/tables/${tableId}/hold`,
      baseUrl: hosts.TABLE_MANAGEMENT,
      headers: { 'Accept-Language': lang },
    });

  static holdTicketGeneralAdmission = ({ generalAdmissionId, quantity, lang }) =>
    request({
      method: 'POST',
      path: `/v1/general-admissions/${generalAdmissionId}/hold`,
      baseUrl: hosts.TABLE_MANAGEMENT,
      body: {
        quantity,
      },
      headers: { 'Accept-Language': lang },
    });

  static expireHoldTicketTableManagement = ({ holdToken, eventId, lang }) =>
    request({
      method: 'POST',
      path: `/v1/events/${eventId}/hold-token/${holdToken}/expire`,
      baseUrl: hosts.TABLE_MANAGEMENT,
      headers: { 'Accept-Language': lang },
    });
}
