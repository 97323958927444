import { useCallback } from 'react';

import { useUser } from 'services/authClient/useUser';
import GoogleTagManager from 'services/GoogleTagManager';

export function useFollow(type, { id, name, source = 'card' }) {
  const { updateFollowed } = useUser();
  const follow = useCallback(() => {
    GoogleTagManager.addToFavourite({
      entity_type: type,
      item_id: id,
      item_name: name,
      source,
    });

    updateFollowed({ type, id, action: 'save' });
  }, [id, type, name, source]);

  const unfollow = useCallback(() => {
    updateFollowed({ type, id, action: 'unsave' });
  }, [id, type]);

  return { follow, unfollow };
}
